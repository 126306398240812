<template>
  <div class="entry">
    <Layout>
      <developer-sidebar v-if="isLoaded"></developer-sidebar>
      <developer-topbar></developer-topbar>

      <Content v-if="isLoaded" :collapse="isMenuOpen">
        <transition name="slide">
          <router-view></router-view>
        </transition>
      </Content>
    </Layout>
  </div>
</template>

<script>
export default {
  components: {
    Layout: () => import("@/components/GlobalComponents/FdLayout/Layout"),
    DeveloperSidebar: () => import("@/components/Layout/DeveloperSidebar"),
    DeveloperTopbar: () => import("@/components/Layout/DeveloperTopbar"),
    Content: () => import("@/components/GlobalComponents/FdLayout/Content")
  },
  name: "home",
  data() {
    return {
      isLoaded: false
    };
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.getSidebarState;
    }
  },
  watch: {},
  mounted() {
    this.init();
  },
  methods: {
    // ============================ INITIALIZATION =============================
    async init() {
      this.isLoaded = false;
      this.$store.commit("setIsLoading", true);
      // await this.initUserData();
      await this.initPermission();
      this.initMenuState();
      this.$store.commit("setIsLoading", false);
      this.isLoaded = true;
    },
    async initUserData() {
      try {
        this.$store.commit("setIsLoading", true);
        const userData = await this.axios.get(`${this.$developerAPI}/account`);

        this.$auth.user({ ...userData.data, ...userData.data.user });
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    initMenuState() {
      window.innerWidth >= 768
        ? this.$store.commit("openSidebar")
        : this.$store.commit("closeSidebar");
    },
    async initPermission() {
      await this.$store.dispatch("manageAccount/fetchPermissions");
    }
  }
};
</script>

<style lang="scss"></style>
